<template>
  <div class="index">
    <div class="main_layout">
      <a-button type="primary" class="addBtn" @click="showModal">
        新建账号
      </a-button>
      <a-table
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="false"
        :rowKey="record => record.userId"
        :loading="tableLoad"
      >
        <template slot="action" slot-scope="text, record">
          <a-space>
            <a-button class="pointer" @click="editUser(record)">编辑</a-button>
            <a-popconfirm
              title="确定禁用该用户吗？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="confirm(record)"
              v-if="record.status === 1"
            >
              <a-button color="green" class="zc_class">
                正常
              </a-button>
            </a-popconfirm>
            <a-popconfirm
              title="确定启用该用户吗？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="confirm(record)"
              v-if="record.status === 0"
            >
              <a-button class="pointer">
                已禁用
              </a-button>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
      <div class="page_warp">
        <a-pagination
          class="pagination"
          v-model="page.current"
          :total="page.total"
          show-less-items
          :defaultPageSize="page.size"
          @change="pageCurrentChange"
        />
      </div>
    </div>
    <a-modal
      :title="this.formData.userId ? '编辑账号' : '新建账号'"
      :visible="visible"
      okText="确定"
      cancelText="取消"
      @ok="handleOk(formData.userId)"
      @cancel="handleCancel"
    >
      <a-form-model
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        :model="formData"
        ref="userForm"
        :rules="rules"
      >
        <a-form-model-item label="登录名" prop="account">
          <a-input v-model="formData.account" />
        </a-form-model-item>
        <a-form-model-item label="昵称" prop="userName">
          <a-input v-model="formData.userName" />
        </a-form-model-item>
        <a-form-model-item label="密码" prop="passWord">
          <a-input-password v-model="formData.passWord" />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="phone">
          <a-input
            v-model="formData.phone"
            onInput="if(value.length>11)value=value.slice(0,11)"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import API from "@/request";

export default {
  name: "index",
  components: {},
  data() {
    let validatePhone = (rule, value, callback) => {
      let reg = /^1((3[0-9])|(4[1579])|(5[0-9])|(6[6])|(7[0-9])|(8[0-9])|(9[0-9]))\d{8}$/
      if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };
    return {
      tableLoad: false,
      visible: false,
      userRoleData: [],
      tableData: [],
      page: {
        total: 0,
        current: 1,
        size: 10
      },
      formData: {
        account: "",
        userName: "",
        passWord: "",
        phone: "",
        roleId: [],
        userId: ""
      },
      rules: {
        roleId: [
          {
            required: true,
            message: "请选择角色",
            trigger: "change"
          },
        ],
        account: [
          {
            required: true,
            message: "请输入登录名",
            trigger: "blur"
          },
          {
            min: 3,
            message: "最少输入三个字符",
            trigger: "change"
          },
        ],
        userName: [
          {
            required: true,
            message: "请输入昵称",
            trigger: "blur"
          },
          {
            min: 2,
            message: "最少输入两个字符",
            trigger: "change"
          },
        ],
        passWord: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur"
          },
          {
            min: 6,
            message: "最少输入六个字符",
            trigger: "change"
          },
        ],
        phone: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur"
          }
        ],
      },
      columns: [
        {
          title: "登录名",
          dataIndex: "account"
        },
        {
          title: "昵称",
          dataIndex: "userName"
        },
        {
          title: "操作",
          dataIndex: "aaa",
          width: "375px",
          scopedSlots: { customRender: "action" }
        }
      ]
    };
  },
  created() {
    this.getList();
    this.getRoleList();
  },
  methods: {
    pageCurrentChange(val) {
      // 分页页码变化
      this.page.current = val;
      this.getList();
    },
    async editUser(key) {
      let res = await API.userById({ userId: key.userId });
      if (res.data.code === "0000") {
        var userData = res.data.data;
        this.visible = true;
        this.formData = {
          account: userData.account,
          userName: userData.userName,
          passWord: userData.passWord,
          phone: userData.phone,
          userId: userData.userId
        };
      }
    },
    showModal() {
      this.visible = true;
    },
    handleOk(id) {
      this.$refs.userForm.validate(async valid => {
        if (valid) {
          let res;
          let message;
          if (!id) {
            res = await API.addUser({
              account: this.formData.account,
              userName: this.formData.userName,
              password: this.formData.passWord,
              phone: this.formData.phone
            });
            message = "新增成功";
          } else {
            res = await API.editorUser({
              account: this.formData.account,
              userName: this.formData.userName,
              password: this.formData.passWord,
              phone: this.formData.phone,
              userId: this.formData.userId
            });
            message = "修改成功";
          }
          if (res.data.code === "0000") {
            this.$message.success(message);
            this.visible = false;
            this.formData = {
              account: "",
              userName: "",
              password: "",
              roleId: [],
              userId: ""
            };
          }

          await this.getList();
        }
      });
    },
    handleCancel() {
      if (this.$refs.userForm != undefined) {
        this.$refs.userForm.resetFields();
      }
      this.formData = {
        account: "",
        userName: "",
        passWord: "",
        userId: ""
      };
      this.visible = false;
    },
    async getRoleList(current = 1, size = 1000, groupId = 0, name = "") {
      let res = await API.getRolePageList({ current, size, groupId, name });
      if (res.data.code === "0000") {
        this.userRoleData = res.data.data.records;
      }
    },
    async deleteUser(item) {
      try {
        let result = await API.deleteUser({ userId: item.userId });
        if (result.data.code === "0000") {
          await this.getList();
        }
      } catch (e) {
        this.$message.error("删除错误");
      }
    },
    async confirm(v) {
      let val = v.status === 1 ? 0 : 1;
      let res = await API.changeUserStatus({ status: val, userId: v.userId });
      if (res.data.code === "0000") {
        await this.getList();
      }
    },
    async getList(
      current = this.page.current,
      size = this.page.size,
      groupId = 0
    ) {
      this.tableLoad = true;
      let res = await API.getAccountList({ current, size, groupId });
      if (res.data.code === "0000") {
        let data = res.data.data;
        this.tableData = data.records;
        this.tableLoad = false;
        this.page = {
          // 设置分页信息
          current: data.current,
          total: data.total,
          size: data.size
        };
      }
    }
  }
};
</script>

<style lang="less" scoped>
.page_warp {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.pageHead {
  background: #ffffff;
}
.addBtn {
  margin-bottom: 16px;
}
.zc_class {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
</style>
